
export default {
  name: 'Index',
  auth: false,
  data() {
    return {
      links: ['/login'],
    };
  },
  head() {
    return {
      title: this.$helpers.pageTitle('Index'),
    };
  },
};
